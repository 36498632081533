<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>试题标签管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <el-input placeholder="试卷名称" v-model="searchForm.name"></el-input>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="srchBtn">搜索</el-button></div>
      </div>
      <div class="operate_btn">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addTest">添加</el-button>
        <el-button type="danger" :plain="true" icon="el-icon-delete" @click="deleteAll">批量删除</el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange" height="100%">
        <el-table-column type="selection" width="55" />
        <el-table-column fixed prop="id" label="ID" width="100" />
        <el-table-column prop="name" label="标签名称" />
        <el-table-column prop="createTime" label="创建时间" width="240" />
        <el-table-column prop="creatorName" label="创建人" width="120" />
        <el-table-column width="120" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click="editTask(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" circle icon='el-icon-delete' @click="deleteLabel(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
    <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination> -->
    <!--新增编辑-->
    <el-dialog :visible.sync="editDialog" width="30%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from :key="timer" :operateBox="operateBox" @closed="closed"></edit-from>
    </el-dialog>
  </div>
</template>

<script>
import EditFrom from './components/edit-from'
import api from '@/api/index'
export default {
  name: 'index',
  components: { EditFrom },
  data() {
    return {
      tableData: [],
      pageBox: {
        pageSize: 10,
        total: 0,
        pageNum: 1
      },
      searchForm: {
        name: '',
        userId: 0
      },
      editDialog: false,
      timer: null,
      operateBox: {
        title: '添加试题标签',
        operate: 'add',
        row: null
      }
    }
  },
  created() {
    this.fetchUserPower()
    // this.test()
  },
  methods: {
    // async test(){
    //   const data={
    //     userId:123
    //   }
    //   let res= await api.test(data)
    //   console.log("结果！！！",res);
    // },

    // 根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        const res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          // if (this.$store.state.role !== 'admin') {
          // this.searchForm.communityIds = res.data.comId
          this.searchForm.userId = this.$store.state.userId
          // }
          this.getLabelList(this.searchForm)
        }
      } catch (e) {

      }
    },

    async getLabelList(data) {
      try {
        console.log('data:', data)
        const res = await api.getExameTitileType(data, this.pageBox)
        console.log('res:', res)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {
        this.$message.warning(e.msg)
      }
    },

    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getLabelList(this.searchForm)
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getLabelList(this.searchForm)
    },
    srchBtn() {
      this.getLabelList(this.searchForm)
    },
    // 新增样例
    addTest() {
      this.operateBox = {
        title: '新增试题标签',
        operate: 'add',
        row: null
      }
      this.editDialog = true
      this.timer = new Date().getTime()
    },
    editTask(row) {
      this.operateBox = {
        title: '修改试题标签',
        operate: 'edit',
        row: row
      }
      this.editDialog = true
      this.timer = new Date().getTime()
    },
    // 选中要删除的对应的id
    handleSelectionChange(val) {
      const ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      this.delIds = ids
    },
    // 批量删除按钮
    deleteAll() {
      if (this.delIds == null) {
        this.$message.info('请选择删除项')
        return false
      }
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delInfo(this.delIds)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    deleteLabel(row) {
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delInfo([row.id])
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 删除或批量删除小组
    async delInfo(ids) {
      try {
        const res = await api.labelDel(ids)
        if (res.code == 200) {
          this.getLabelList(this.searchForm)
          this.$message({
            type: 'success',
            message: '删除成功'
          })
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    closed() {
      console.log(11111)
      this.editDialog = false
      this.getLabelList(this.searchForm)
    }
  },
  watch: {}
}
</script>

<style scoped>
.el-pagination {
  text-align: center;
}
</style>
