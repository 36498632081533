<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" :label-position="labelPosition"
      class="demo-ruleForm">
      <el-form-item label="试题标签名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="名称长度不得超过15个字符" :maxlength="15"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
        <el-button @click="cancelFrom">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from '@/api/index'
import debounce from 'lodash/debounce'
export default {
  name: 'edit-from',
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      labelPosition: 'top',
      ruleForm: {
        name: ''
      },
      rules: {
        name: [{ required: true, message: '请输入标签名称', trigger: 'blur' }]
      }
    }
  },
  created() {
    if (this.operateBox.row != null) {
      this.ruleForm = this.operateBox.row
    }
  },
  methods: {
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.operateBox.operate == 'add') {
            this.addLabel({
              name: this.ruleForm.name,
              creator: 'admimTest'
            })
          } else {
            this.updateLabel(this.ruleForm)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }, 500),
    async addLabel(data) {
      var _this = this
      try {
        const res = await api.labelAdd(data)
        if (res.code == 200) {
          this.$message.success('新增成功')
          _this.$emit('closed')
        } else {
          this.$message.warning(res.msg)
        }
      } catch (e) {
        this.$message.warning(e.msg)
      }
    },
    async updateLabel(data) {
      try {
        const res = await api.labelUpdate(data)
        if (res.code == 200) {
          this.$message.success('修改成功')
          this.$emit('closed')
        } else {
          this.$message.warning(res.msg)
        }
      } catch (e) {
        this.$message.warning(e.msg)
      }
    },
    cancelFrom() {
      this.$emit('closed')
    }
  }
}
</script>

<style scoped></style>
